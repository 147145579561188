import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SobreComponent } from './home/sobre/sobre.component';
import { ContatoComponent } from './contato/contato.component';
import { ConsorcioComponent } from './home/consorcio/consorcio.component';
import { ModalidadesComponent } from './home/modalidades/modalidades.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'sobre', component: SobreComponent },
  { path: 'contato', component: ContatoComponent },
  { path: 'consorcio', component: ConsorcioComponent },
  { path: 'modalidades', component: ModalidadesComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
