import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ClientesComponent } from './home/clientes/clientes.component';
import { TimeComponent } from './home/time/time.component';
import { SolucoesComponent } from './home/solucoes/solucoes.component';
import { ModalidadesComponent } from './home/modalidades/modalidades.component';
import { ConsorcioComponent } from './home/consorcio/consorcio.component';
import { SobreComponent } from './home/sobre/sobre.component';
import { ContatoComponent } from './contato/contato.component';
import { PlanosComponent } from './home/planos/planos.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ClientesComponent,
    TimeComponent,
    ModalidadesComponent,
    SolucoesComponent,
    SobreComponent,
    ConsorcioComponent,
    PlanosComponent,
    ContatoComponent,
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
