import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modalidades',
  templateUrl: './modalidades.component.html',
  styleUrls: ['./modalidades.component.css']
})
export class ModalidadesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
